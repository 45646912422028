import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-theme-localization';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const NavItemLink = styled(Link)`
  font-family: ${(props) => props.theme.fontFamilies.header};
  text-decoration: none;
  padding: 10px;
  background-color: ${(props) =>
    props.$sideNavStyle
      ? props.theme.sideNavBackgroundColor
      : props.theme.topNavButtonBackgroundColor};
  padding: 0.75em 10px;
  display: block;
  margin-top: 3px;
  margin-left: 5px;
  font-weight: normal;
  font-style: normal;
  font-size: ${(p) => p.theme.typeSettings.navItems.fontSize};
  /* letter-spacing: ${(p) => p.theme.typeSettings.navItems.letterSpacing}; */
  text-transform: uppercase;
  line-height: 1em;
  text-shadow: none;

  -webkit-transition: color 0.1s 0s ease-in-out;
  -moz-transition: color 0.1s 0s ease-in-out;
  -ms-transition: color 0.1s 0s ease-in-out;
  -o-transition: color 0.1s 0s ease-in-out;
  transition: color 0.1s 0s ease-in-out;

  &:hover {
    color: ${(props) =>
      props.$activePage
        ? props.theme.topNavLinks.active
        : props.theme.topNavLinks.hover};
  }
  &:not(:hover) {
    color: ${(props) => {
      if (props.$sideNavStyle) {
        return props.$activePage
          ? props.theme.sideNavLinks.active
          : props.theme.sideNavLinks.default;
      } else {
        return props.$activePage
          ? props.theme.topNavLinks.active
          : props.theme.topNavLinks.default;
      }
    }};
  }
`;

const NavItemExternalLink = styled.a`
  font-family: ${(props) => props.theme.fontFamilies.header};
  text-decoration: none;
  padding: 10px;
  background-color: ${(props) =>
    props.$sideNavStyle
      ? props.theme.sideNavBackgroundColor
      : props.theme.topNavButtonBackgroundColor};
  padding: 0.75em 10px;
  display: block;
  margin-top: 3px;
  margin-left: 5px;
  font-weight: normal;
  font-style: normal;
  font-size: ${(p) => p.theme.typeSettings.navItems.fontSize};
  /* letter-spacing: ${(p) => p.theme.typeSettings.navItems.letterSpacing}; */
  text-transform: uppercase;
  line-height: 1em;
  text-shadow: none;

  -webkit-transition: color 0.1s 0s ease-in-out;
  -moz-transition: color 0.1s 0s ease-in-out;
  -ms-transition: color 0.1s 0s ease-in-out;
  -o-transition: color 0.1s 0s ease-in-out;
  transition: color 0.1s 0s ease-in-out;

  &:hover {
    color: ${(props) =>
      props.$activePage
        ? props.theme.topNavLinks.active
        : props.theme.topNavLinks.hover};
  }
  &:not(:hover) {
    color: ${(props) => {
      if (props.$sideNavStyle) {
        return props.$activePage
          ? props.theme.sideNavLinks.active
          : props.theme.sideNavLinks.default;
      } else {
        return props.$activePage
          ? props.theme.topNavLinks.active
          : props.theme.topNavLinks.default;
      }
    }};
  }
`;

const NavItemAnchorLink = styled(AnchorLink)`
  font-family: ${(props) => props.theme.fontFamilies.header};
  text-decoration: none;
  padding: 10px;
  background-color: ${(props) =>
    props.$sideNavStyle
      ? props.theme.sideNavBackgroundColor
      : props.theme.topNavButtonBackgroundColor};
  padding: 0.75em 10px;
  display: block;
  margin-top: 3px;
  margin-left: 5px;
  font-weight: normal;
  font-style: normal;
  font-size: ${(p) => p.theme.typeSettings.navItems.fontSize};
    /* letter-spacing: ${(p) => p.theme.typeSettings.navItems.letterSpacing}; */
  text-transform: uppercase;
  line-height: 1em;
  text-shadow: none;

  -webkit-transition: color 0.1s 0s ease-in-out;
  -moz-transition: color 0.1s 0s ease-in-out;
  -ms-transition: color 0.1s 0s ease-in-out;
  -o-transition: color 0.1s 0s ease-in-out;
  transition: color 0.1s 0s ease-in-out;

  &:hover {
    color: ${(props) =>
      props.$activePage
        ? props.theme.topNavLinks.active
        : props.theme.topNavLinks.hover};
  }
  &:not(:hover) {
    color: ${(props) => {
      if (props.$sideNavStyle) {
        return props.$activePage
          ? props.theme.sideNavLinks.active
          : props.theme.sideNavLinks.default;
      } else {
        return props.$activePage
          ? props.theme.topNavLinks.active
          : props.theme.topNavLinks.default;
      }
    }};
  }
`;

const NavItemContainer = styled.span`
  vertical-align: middle;
  display: inline-block;
  margin: 0;
`;

const NavItem = (props) => {

  const { anchor, sideNavStyle, external, preloadNamespaces, target, to, ...otherProps } = props;

  return (
    <NavItemContainer>
      {props.anchor ? (
        <NavItemAnchorLink {...otherProps } $sideNavStyle={props.sideNavStyle ? props.sideNavStyle : props.$sideNavStyle }>{props.children}</NavItemAnchorLink>
      ) : props.external ? (
        <NavItemExternalLink external={external} target={target} to={to} {...otherProps} $sideNavStyle={props.sideNavStyle ? props.sideNavStyle : props.$sideNavStyle } />
      ) : (
        <NavItemLink to={props.to} preloadNamespaces={preloadNamespaces} $sideNavStyle={props.sideNavStyle ? props.sideNavStyle : props.$sideNavStyle } {...otherProps} />
      )}
    </NavItemContainer>
  );
};

export default NavItem;
