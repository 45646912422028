import styled from 'styled-components'

export const BigButton = styled.button`
  font-family: ${(props) => props.theme.fontFamilies.paragraph};
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 4px;
  background-color: ${p => p.backgroundColor ? p.theme.colors[p.backgroundColor] : p.theme.colors.jiarPurple};
  color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.jiarEggshell};
  cursor: pointer !important;
  letter-spacing: -0.5px;
  border: none;

  @media(max-width: 760px) {
    font-size: 14px;
    line-height: 16px;
    margin-right: auto;
    padding: 10px;
  }

  @media(min-width: 761px) {
    font-size: 20px;
    line-height: 22px;
    padding: 10px;
  }
`

export default BigButton