import { useEffect, useState, useMemo } from "react";

function useScrollbarWidth() {
  const isClient = typeof window === 'object';

  const getWidth = useMemo(() => () => {
    return isClient ? window.innerWidth - document.body.clientWidth : undefined;
  }, [isClient])

  const [scrollbarWidth, setScrollbarWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setScrollbarWidth(getWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getWidth, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return scrollbarWidth;
}

export default useScrollbarWidth;
