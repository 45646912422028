import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { globalHistory as history } from '@reach/router';
import { HamburgerSqueeze as Hamburger } from 'react-animated-burgers';
import AssetsContext from './assetsContext';
import Img from 'gatsby-image'

import { Link } from 'gatsby-theme-localization';
import NavItem from './NavComponents/NavItem';
import NewLanguageSwitcher from './NavComponents/NewLanguageSwitcher';

const TopNavBar = styled.div`
  background-color: ${(props) => props.theme.topNavBarBackgroundColor};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* height: 67px; */
`;

const TopNavContentsContainer = styled.div`
  max-width: 990px;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    padding-left: 0;
    justify-content: space-between;
  }

`;

const TopNavLogoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 9px;
  margin-right: auto;
  margin-left: auto;
  
  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TopNavLogo = styled(Img)`
  margin-left: auto;
  margin-right: 10px;
  display: block!important;

  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    margin-right: auto;
  }
`;

const StyledLanguageSwitcher = styled(NewLanguageSwitcher)`
  height: 100%;
  vertical-align: middle;
  padding-left: 0px;
  margin-right: auto;
  display: flex;
  flex: 1;

  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    padding-left: 5px;
    margin-left: 0;
  }
`;

const SideNavTogglerContainer = styled.div`
  /* width: ${(props) => (props.sideNavView ? '111px' : '')}; */
  padding-top: 5px;
  /* margin-left: auto; */
  padding-right: 5px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SideNavToggler = styled(Hamburger)`
  border: none;
  float: right;

  div {
    span {
      background-color: ${(props) => props.theme.hamburgerButtonColor};
      &:before {
        background-color: ${(props) => props.theme.hamburgerButtonColor};
      }
      &:after {
        background-color: ${(props) => props.theme.hamburgerButtonColor};
      }
    }
  }
`;

const NavItemsContainer = styled.div`
  /* padding-left: ${(props) => (props.sideNavView ? '0px' : '10px')}; */
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    display: none;
  }
`;

const ConditionalSideNavContainer = styled.div`
  display: none;
  width: 100%;
  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    display: contents;
  }
`

const ConditionalTopNavContainer = styled.div`
  display: contents;
  width: 100%;
  @media (max-width: ${p => p.navBreakpoints.mobile}px) {
    display: none;
  }
`


const TopNav = (props) => {
  const [t, i18n] = useTranslation();
  const { location } = history;
  let lang = i18n.language === 'bn' ? 'en' : i18n.language
  const navLogo = useContext(AssetsContext).navImagesByLang[lang]

  const navItems = t('navItems', { returnObjects: true })
  const navBreakpoints = t('navBreakpoints', { returnObjects: true })

  // console.log(navBreakpoints)
  let splitLocation = location.pathname.split('/');
  let pageName = splitLocation[2]

  return (
    <TopNavBar id={props.id}>
      <TopNavContentsContainer navBreakpoints={navBreakpoints} >
        <ConditionalSideNavContainer navBreakpoints={navBreakpoints}>
          <StyledLanguageSwitcher navBreakpoints={navBreakpoints}  />
          <TopNavLogoContainer navBreakpoints={navBreakpoints} >
            <Link to="/" preloadNamespaces={['translation', 'index']}>
              <TopNavLogo
                navBreakpoints={navBreakpoints} 
                
                fixed={navLogo}
              />
            </Link>
          </TopNavLogoContainer>
          <SideNavTogglerContainer navBreakpoints={navBreakpoints} >
            <SideNavToggler
              isActive={props.sideNavOpen}
              type={'arrow'}
              toggleButton={props.toggleSideNav}
            />
          </SideNavTogglerContainer>
        </ConditionalSideNavContainer>
        <ConditionalTopNavContainer navBreakpoints={navBreakpoints}>
            <TopNavLogoContainer navBreakpoints={navBreakpoints} >
              <Link to="/" preloadNamespaces={['translation', 'index']}>
                <TopNavLogo
                  navBreakpoints={navBreakpoints} 
                  
                  fixed={navLogo}
                />
              </Link>
            </TopNavLogoContainer>
            <StyledLanguageSwitcher navBreakpoints={navBreakpoints}  />
            <NavItemsContainer navBreakpoints={navBreakpoints}>
              { navItems && navItems.map(navItem =>
                <NavItem
                  key={navItem.path+'topnavbutton'}
                  to={navItem.type === "page" ? navItem.path : navItem.type === "anchor" && pageName !== navItem.pageName ? `${navItem.pageName.length > 0 ? '/' + navItem.pageName : ''}/${navItem.path}`: undefined}
                  $activePage={pageName === navItem.pageName ? navItem.pageName : undefined}
                  preloadNamespaces={navItem.type === "page" && navItem.preloadNamespaces ? navItem.preloadNamespaces : []}
                  anchor={navItem.type === "anchor" && pageName === navItem.pageName ? true : undefined}
                  external={navItem.type === "external" ? true : undefined }
                  href={navItem.type === "external" || "anchor" ? navItem.path : undefined}
                  target={navItem.type === "external" ? "_blank" : undefined}
                >
                  {navItem.title}
                </NavItem>
              )}
            </NavItemsContainer>
          </ConditionalTopNavContainer>
      </TopNavContentsContainer>
    </TopNavBar>
  );
};

export default TopNav;
