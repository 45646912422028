import styled from 'styled-components';

const BodyPanelContainer = styled.div`
  padding-top: ${p => p.unifiedBody ? 0 : 20}px;
  padding-bottom: ${p => p.unifiedBody ? 15 : 20}px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: ${p => p.unifiedBody ? '0' : '10'}px;
  height: auto;
  width: 100%;
  display: block;
  clear: both;
  position: relative;
  outline: none;
  box-shadow: inset 0 0 0 1px rgba(128, 128, 128, 0);
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -ms-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.white};

  &:first-child {
    ${p => p.unifiedBody ? `
      padding-top: 20px;
      margin-top: 10px;
    ` : `
    `}
  }

  &:last-child {
    ${p => p.unifiedBody ? `
      padding-bottom: 20px;
    `:``}
  }
`;

export default BodyPanelContainer;
