import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import '../style.css';
import { GlobalStyle, defaultTheme } from './GlobalStyledComponents';
import TopNav from './LocalizedTopNav';
import SideNav from './LocalizedSideNav';
import Footer from './Footer';
import Modal from './Modal';
import { ModalProvider } from './modalContext';

// favicons
import {Helmet} from 'react-helmet';
// import appleTouchIcon from '../images/apple-touch-icon.png';
// import favicon32 from '../images/favicon-32x32.png';
// import favicon16 from '../images/favicon-16x16.png';
// import maskIcon from '../images/safari-pinned-tab.svg';

// fonts

import InterstateLightOTF from '../fonts/interstate-light.otf';
import InterstateLightWOFF from '../fonts/interstate-light.woff';
import InterstateLightWOFF2 from '../fonts/interstate-light.woff2';
import InterstateOTF from '../fonts/interstate.otf';
import InterstateWOFF from '../fonts/interstate.woff';
import InterstateWOFF2 from '../fonts/interstate.woff2';
import InterstateBoldOTF from '../fonts/interstate-bold.otf';
import InterstateBoldWOFF from '../fonts/interstate-bold.woff';
import InterstateBoldWOFF2 from '../fonts/interstate-bold.woff2';
import InterstateCondensedOTF from '../fonts/interstate-condensed.otf';
import InterstateCondensedWOFF from '../fonts/interstate-condensed.woff';
import InterstateCondensedWOFF2 from '../fonts/interstate-condensed.woff2';
import InterstateBoldCondensedOTF from '../fonts/interstate-bold-condensed.otf';
import InterstateBoldCondensedWOFF from '../fonts/interstate-bold-condensed.woff';
import InterstateBoldCondensedWOFF2 from '../fonts/interstate-bold-condensed.woff2';

import useWindowDimensions from '../hooks/useWindowDimensions';
import { siteMetadata } from '../../gatsby-config';
import { useTranslation } from 'react-i18next';

const ViewContainer = styled.div`
  ${(props) =>
    props.sideNavOpen
      ? `
    -webkit-transform: translatex(-260px) translatez(0);
    -moz-transform: translatex(-260px) translatez(0);
    -ms-transform: translatex(-260px) translatez(0);
    -o-transform: translatex(-260px) translatez(0);
    transform: translatex(-260px) translatez(0);
    -webkit-transform: translatex(-260px);
    -webkit-transform: translate3d(-260px,0,0);
    -moz-transform: translatex(-260px) translatez(0);
    -moz-transform: translate3d(-260px,0,0);
    -ms-transform: translatex(-260px) translatez(0);
    -ms-transform: translate3d(-260px,0,0);
    -o-transform: translatex(-260px) translatez(0);
    -o-transform: translate3d(-260px,0,0);
    transform: translatex(-260px) translatez(0);
    transform: translate3d(-260px,0,0);
  `
      : ''}

    -webkit-transition: -webkit-transform ${(props) =>
      props.sideNavOpen ? '.2s ease-in-out' : '0s .2s linear'};
    -moz-transition: -moz-transform ${(props) =>
      props.sideNavOpen ? '.2s ease-in-out' : '0s .2s linear'};
    -ms-transition: -ms-transform ${(props) =>
      props.sideNavOpen ? '.2s ease-in-out' : '0s .2s linear'};
    -o-transition: -o-transform ${(props) =>
      props.sideNavOpen ? '.2s ease-in-out' : '0s .2s linear'};
    transition: transform ${(props) =>
      props.sideNavOpen ? '.2s ease-in-out' : '0s .2s linear'};
    display: block;
  
`;

const Layout = ({ children }) => {
  const { width } = useWindowDimensions();
  // const metadata = useContext(AssetsContext).siteMetadata;
  const [navState, setNavState] = useState({
    sideNav: false,
    sideNavOpen: false,
  });

  let [t] = useTranslation()
  let navBreakpoints = t('navBreakpoints', { returnObjects: true })

  function toggleSideNav() {
    setNavState({
      ...navState,
      sideNavOpen: !navState.sideNavOpen,
    });
  }

  if (!navState.sideNav && width < navBreakpoints.mobile) {
    setNavState({
      ...navState,
      sideNav: true,
    });
  }

  if (navState.sideNav && width >= navBreakpoints.mobile) {
    setNavState({
      ...navState,
      sideNav: false,
    });
  }

  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Sandy Nurse for NYC Council in Bushwick, East New York, Cypress Hills, Ocean Hill and Brownsville, Brooklyn"/>
        <meta property="og:image" content="/sandy-flyer.png" />
        <link rel="preload" as="font" href={InterstateLightOTF} type="font/otf" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateLightWOFF} type="font/woff" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateLightWOFF2} type="font/woff2" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateOTF} type="font/otf" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateWOFF} type="font/woff" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateWOFF2} type="font/woff2" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldOTF} type="font/otf" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldWOFF} type="font/woff" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldWOFF2} type="font/woff2" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateCondensedOTF} type="font/otf" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateCondensedWOFF} type="font/woff" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateCondensedWOFF2} type="font/woff2" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldCondensedOTF} type="font/otf" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldCondensedWOFF} type="font/woff" crossOrigin="anonymous"/>
        <link rel="preload" as="font" href={InterstateBoldCondensedWOFF2} type="font/woff2" crossOrigin="anonymous"/>

        <link rel="apple-touch-icon" sizes="180x180" href={'/icons/apple-touch-icon.png?v=2'} />
        <link rel="icon" type="image/png" sizes="32x32" href={'/icons/favicon-32x32.png?v=2'} />
        <link rel="icon" type="image/png" sizes="16x16" href={'/icons/favicon-16x16.png?v=2'} />
        <link rel="manifest" href={'/site.webmanifest?v=2'}/>
        <link rel="mask-icon" href={'/icons/safari-pinned-tab.svg?v=2'} color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico?v=2"/>
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />

      </Helmet>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <ModalProvider>
            <Modal />
            <ViewContainer
              sideNavOpen={navState.sideNavOpen}
              onClick={navState.sideNavOpen ? toggleSideNav : () => {}}
            >

            <TopNav
              id="top"
              sideNav={navState.sideNav}
              sideNavOpen={navState.sideNavOpen}
              toggleSideNav={toggleSideNav}
            />
            {children}
            <Footer />

        </ViewContainer>
        </ModalProvider>
        <SideNav
          active={navState.sideNav}
          open={navState.sideNavOpen}
          toggleSideNav={toggleSideNav}
        />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
