import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TopLinkContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  width: 10px;

  > a {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    font-stretch: ultra-expanded;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
`;

const HeadlineH1 = styled.h1`
  width: 90%;
  font-family: ${(props) => props.theme.fontFamilies.header};
  font-size: ${(props) => (props.mobileView ? '24px' : '29px')};
  font-style: normal;
  font-weight: normal;
  text-align: left;
  letter-spacing: ${(props) => props.theme.typeSettings.heading1.letterSpacing};
  line-height: 34.8px;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
  text-shadow: ${(props) =>
    props.shadowColor && `-3px 3px 0px ${props.shadowColor}`};
  color: ${(props) => props.color && props.theme.colors[props.color]};
`;

const HeadlineH2 = styled.h2`
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  font-size: ${(props) => (props.mobileView ? '20px' : '25px')};
  font-style: normal;
  font-weight: normal;
  text-align: left;
  letter-spacing: ${(props) => (props.mobileView ? '0px' : '0px')};
  line-height: ${(props) => (props.mobileView ? '25px' : '34.8px')};
  text-rendering: optimizeLegibility;
  text-shadow: ${(props) =>
    props.shadowColor && `-3px 3px 0px ${props.shadowColor}`};
  color: ${(props) => props.color && props.theme.colors[props.color]};
`;

const HeadlineH3 = styled.h3`
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  font-size: ${(props) => (props.mobileView ? '16px' : '20px')};
  font-style: normal;
  font-weight: normal;
  text-align: left;
  letter-spacing: ${(props) => (props.mobileView ? '-0.1px' : '-0px')};
  line-height: ${(props) => (props.mobileView ? '18px' : '25px')};
  text-rendering: optimizeLegibility;
  text-shadow: ${(props) =>
    props.shadowColor && `-3px 3px 0px ${props.shadowColor}`};
  color: ${(props) => props.color && props.theme.colors[props.color]};
`;

const Headline = (props) => {
  return (
    <HeadlineContainer className={props.className}>
      {
        {
          undefined: <HeadlineH1 {...props}>{props.children}</HeadlineH1>,
          1: <HeadlineH1 {...props}>{props.children}</HeadlineH1>,
          2: <HeadlineH2 {...props}>{props.children}</HeadlineH2>,
          3: <HeadlineH3 {...props}>{props.children}</HeadlineH3>,
        }[props.h]
      }

      {!props.hideTopArrow && !props.hideArrow && (
        <TopLinkContainer>
          <AnchorLink href="#top" />
        </TopLinkContainer>
      )}
    </HeadlineContainer>
  );
};

export default Headline;
