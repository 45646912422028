const path = require('path');

module.exports = {
  siteMetadata: {
    title: `Sandy Nurse for New York City Council`,
    siteUrl: `https://www.sandyforcouncil.com`,
    description: `Sandy Nurse is a builder, organizer, fighter, and candidate for New York City Council in District 37.`,
    author: `@timfitzzz`,
    footerEmail: `info@sandyforcouncil.com`,
    socialAccounts: [
      {
        iconName: `twitter.svg`,
        url: `https://www.twitter.com/SandyForCouncil`,
      },
      {
        iconName: `instagram.svg`,
        url: `http://instagram.com/SandyForCouncil21`,
      },
      {
        iconName: `facebook.svg`,
        url: `https://www.facebook.com/pg/SandyForCouncil21`,
      },
    ],
    fonts: [
      { fontName: 'Interstate', fileName: 'interstate.otf' },
      { fontName: 'Interstate Condensed', fileName: 'interstate-condensed.otf' },
      { fontName: 'Interstate Bold Condensed', fileName: 'interstate-bold-condensed.otf' },
      { fontName: 'Interstate Light', fileName: 'interstate-light.otf'}
    ],
  },
  plugins: [
    {
      resolve: `gatsby-plugin-webpack-bundle-analyser-v2`,
      options: {
        analyzerMode: 'static',
        reportFilename: 'bundle-report.html',
        openAnalyzer: true,
        logLevel: 'info'


      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Sandy Nurse for New York City Council',
        short_name: 'Sandy for Council',
        icons: [
          {
            src: '/icons/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/icons/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#ffffff',
        display: 'standalone',
      },
    },
    `gatsby-plugin-meta-redirect`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-preload-fonts`,
    `gatsby-plugin-styled-components`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: path.join(__dirname, `src`, `images`),
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `fonts`,
        path: path.join(__dirname, `src`, `fonts`),
      },
    },
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    // {
    //   resolve: `gatsby-plugin-react-svg`,
    //   options: {
    //     rule: {
    //       include: /svg/
    //     }
    //   }
    // },
    {
      resolve: `gatsby-theme-localization`,
      options: {
        languages: ['en', 'es', 'bn'],
        namespaces: ['translation', 'index', 'platform', 'joinus', 'howtovote'],
        localesDir: './src/locales',
        allowIndex: false,
        defaultLng: 'en',
        suspenseFallback: require.resolve(`./src/components/fallback`),
        embedTranslations: {
          preloadFallbackLng: true,
          preloadNamespaces: [
            {
              regex: '/.*/',
              namespaces: ['translation', 'index'],
              languages: ['en', 'es', 'bn'],
            },
            // {
            //   regex: '/about/',
            //   namespaces: ['translation', 'about'],
            //   languages: ['en', 'es'],
            // },
            {
              regex: '/platform/',
              namespaces: ['translation', 'platform'],
              languages: ['en', 'es', 'bn'],
            },
            {
              regex: '/joinus/',
              namespaces: ['translation', 'joinus'],
              languages: ['en', 'es', 'bn']
            },
            {
              regex: '/howtovote/',
              namespaces: ['translation', 'howtovote'],
              languages: ['en', 'es', 'bn']
            }
          ],
        },
        i18next: {
          fallbackLng: 'en',
          react: {
            wait: true,
            useSuspense: true,
          },
          debug: process.env.NODE_ENV !== 'production',
        },
        i18nPlugin: {
          // whatever you want to pass to gatsby-plugin-i18n
          langKeyDefault: 'en',
          useLangKeyLayout: false,
        },
      },
    },
  ],
};
