import { createGlobalStyle } from 'styled-components';

import InterstateLightOTF from '../fonts/interstate-light.otf';
import InterstateLightWOFF from '../fonts/interstate-light.woff';
import InterstateLightWOFF2 from '../fonts/interstate-light.woff2';
import InterstateOTF from '../fonts/interstate.otf';
import InterstateWOFF from '../fonts/interstate.woff';
import InterstateWOFF2 from '../fonts/interstate.woff2';
import InterstateBoldOTF from '../fonts/interstate-bold.otf';
import InterstateBoldWOFF from '../fonts/interstate-bold.woff';
import InterstateBoldWOFF2 from '../fonts/interstate-bold.woff2';
import InterstateCondensedOTF from '../fonts/interstate-condensed.otf';
import InterstateCondensedWOFF from '../fonts/interstate-condensed.woff';
import InterstateCondensedWOFF2 from '../fonts/interstate-condensed.woff2';
import InterstateBoldCondensedOTF from '../fonts/interstate-bold-condensed.otf';
import InterstateBoldCondensedWOFF from '../fonts/interstate-bold-condensed.woff';
import InterstateBoldCondensedWOFF2 from '../fonts/interstate-bold-condensed.woff2';
const colors = {
  transparent: 'transparent',
  orangeYellow: '#ffb60a',
  darkGold: '#F2BB05',
  gold: 'gold',
  yellow: '#fed600',
  translucentYellow: 'rgba(254, 214, 0, 0.8)',
  green: '#5fc6c6',
  magenta: '#d34097',
  white: '#FFF',
  lightgray: 'lightgray',
  darkgray: 'darkgray',
  black: '#000',
  translucentGray: 'rgba(10,10,10,0.4)',
  lessTranslucentGray: 'rgba(10,10,10,0.6)',
  backOfHouseGray: '#212121',
  translucentWhite: 'rgba(255, 255, 255, 0.74)',
  robinsEggBlue: '#00c2cb',
  dodgerBlue: '#5271ff',
  lavender: '#cb6ce6',
  none: '',
  jiarGold: 'rgb(250, 166, 26)',
  jiarTranslucentGold: 'rgba(250, 166, 26, 0.85)',
  jiarOrange: 'rgb(242, 97, 35)',
  jiarSalmon: 'rgb(241, 102, 124)',
  jiarTeal: 'rgb(0, 174, 178)',
  jiarFadedTeal: 'rgba(0, 174, 178, 0.5)',
  jiarTranslucentTeal: 'rgba(0, 174, 178, 0.85)',
  jiarPurple: 'rgb(202, 67, 152)',
  jiarDarkGreen: 'rgb(0, 58, 66)',
  jiarEggshell: 'rgb(244, 242, 237)',
};

export const defaultTheme = {
  siteConfig: {
    maxContentWidth: 990,
    breakpoints: {
      mobile: 750,
      smallMobile: 380
    }
  },
  colors,
  links: {
    default: colors.jiarSalmon,
    visited: colors.jiarDarkGreen,
    hover: colors.jiarPurple,
    shadowColor: colors.none,
  },
  topNavLinks: {
    active: colors.jiarGold,
    default: colors.black,
    hover: colors.jiarPurple,
  },
  topNavBarBackgroundColor: 'white',
  topNavButtonBackgroundColor: 'none',
  sideNavLinks: {
    active: colors.darkGold,
    default: colors.white,
    hover: colors.jiarDarkGreen,
  },
  sideNavBackgroundColor: colors.backOfHouseGray,
  signupWidget: {
    background: colors.translucentWhite,
    titleBackground: colors.none,
    titleColor: colors.jiarGold,
    titleShadowColor: colors.black,
  },
  headerBreakoutSection: {
    background: colors.transparent,
  },
  issuesPanel: {
    backgroundColor: colors.white,
  },
  bodyPanelContainer: `
        padding: 17px;
        height: auto;
        width: 100%;
        display: block;
        clear: both;
        position: relative;
        outline: none;
        box-shadow: inset 0 0 0 1px rgba(128,128,128,0);
        -webkit-transition: box-shadow .2s ease-in-out;
        -moz-transition: box-shadow .2s ease-in-out;
        -ms-transition: box-shadow .2s ease-in-out;
        -o-transition: box-shadow .2s ease-in-out;
        -webkit-transition: box-shadow .2s ease-in-out;
        transition: box-shadow .2s ease-in-out;
        -webkit-font-smoothing: subpixel-antialiased;
        overflow: hidden;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 20px;

    `,
  hamburgerButtonColor: colors.lessTranslucentGray,
  fontFamilies: {
    header: 'interstate-condensed-bold',
    subHeader: 'interstate-condensed',
    paragraph: 'interstate-light',
    bold: 'interstate-bold',
    links: 'interstate'
  },
  typeSettings: {
    heading1: {
      letterSpacing: '0px',
    },
    paragraph: {
      letterSpacing: '0px',
      fontSize: '',
    },
    navItems: {
      letterSpacing: '1px',
      fontSize: '20px',
    },
    languageSwitcher: {
      desktop: {
        letterSpacing: 'normal',
        fontSize: '20px',
        iconFontSize: '14px',
        switcherLineHeight: '21px',
        buttonWidth: '124px',
        currentButtonWidth: '125px'
      },
      mobileView: {
        letterSpacing: 'normal',
        fontSize: '16px',
        iconFontSize: '12px',
        switcherLineHeight: '17px',
        buttonWidth: '110px',
        currentButtonWidth: '111px'
      }
    },
  },
  endorsements: {
    thumbBorderColor: colors.jiarPurple,
    thumbBackgroundColor: colors.jiarEggshell
  },
  ctaColors: {
    1: { background: colors.orangeYellow, text: colors.magenta },
    2: { background: colors.green, text: colors.yellow },
    3: { background: colors.orangeYellow, text: colors.magenta },
  },
  footer: {
    colors: {
      text: colors.black,
      background: colors.jiarPurple,
      link: colors.black,
      linkHover: colors.jiarGold,
    },
  },
};

export const GlobalStyle = createGlobalStyle`
/* 
    * {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: grayscale;
    } */

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @font-face {
        font-family: 'interstate-light';
        src: local('Interstate Light'),
        url(${InterstateLightWOFF2}) format('woff2'),
        url(${InterstateLightWOFF}) format('woff'),
        url(${InterstateLightOTF}) format('opentype');
        font-display: block;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'interstate';
        src: local('Interstate'),
        url(${InterstateWOFF2}) format('woff2'),
        url(${InterstateWOFF}) format('woff'),
        url(${InterstateOTF}) format('opentype');
        font-display: block;
        font-style: normal;
        font-weight: 500;
    }

    @font-face {
        font-family: 'interstate-bold';
        src: local('Interstate Bold'),
        url(${InterstateBoldWOFF2}) format('woff2'),
        url(${InterstateBoldWOFF}) format('woff'),
        url(${InterstateBoldOTF}) format('opentype');
        font-display: block;
        font-style: normal;
        font-weight: 700;
    }


    @font-face {
        font-family: 'interstate-condensed';
        src: local('Interstate Condensed'), local('InterstateCondensed'),
        url(${InterstateCondensedWOFF2}) format('woff2'),
        url(${InterstateCondensedWOFF}) format('woff'),
        url(${InterstateCondensedOTF}) format('opentype');
        font-display: block;
        font-style: normal;
        font-weight: 500;
    }

    @font-face {
        font-family: 'interstate-condensed-bold';
        src: local('Interstate Condensed'), local('InterstateCondensed'),
        url(${InterstateBoldCondensedWOFF2}) format('woff2'),
        url(${InterstateBoldCondensedWOFF}) format('woff'),
        url(${InterstateBoldCondensedOTF}) format('opentype');
        font-display: block;
        font-style: normal;
        font-weight: 700;
    }

    p {
      display: block;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-family: ${(props) => props.theme.fontFamilies.paragraph};
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 25.6px;
    }

    li {
        font-family: ${(props) => props.theme.fontFamilies.paragraph};
    }

    b {
      font-family: 'interstate-bold';
    }

    a {
        text-decoration: none;
        text-shadow: ${(props) => {
          if (props.theme.links.shadowColor !== '') {
            return `1px -1px 0px ${props.theme.links.shadowColor}`;
          } else {
            return `none`;
          }
        }};
        color: ${(props) => props.theme.links.default};
        font-family: 'interstate';

        &:visited {
            color: ${(props) => props.theme.links.visited};
        }

        &:hover {
            color: ${(props) => props.theme.links.hover};
        }
    }

    h1 {
        display: block;
        margin-block-start: 0.2em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: ${(props) => props.theme.fontFamilies.header};
        font-weight: normal;
        font-feature-settings: "calt", "clig", "kern", "liga", "locl", "rlig";
        font-kerning: auto;
        font-language-override: normal;
        font-optical-sizing: auto;
        font-size-adjust: none;
        font-stretch: 100%;
        font-style: normal;
        font-variant: normal;
        font-synthesis: weight style;
    }

    body {
      background-color: ${(p) => p.theme.colors.jiarEggshell};
    }

`;
