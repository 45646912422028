import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AssetsContext from './assetsContext';

const FooterContainer = styled.div`
  ${(props) => props.theme.bodyPanelContainer};
  background-color: ${(props) => props.theme.footer.colors.background};
  color: ${(props) => props.theme.footer.colors.text};
`;

const FooterContent = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.paragraph};
  font-weight: normal;
  text-transform: uppercase;

  a {
    color: ${(props) => props.theme.footer.colors.link};
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.footer.colors.linkHover};
    }
  }
`;
const SocialIconsContainer = styled.div`
  width: 150px;
  left: 0;
  right: 0;
  margin: auto;

  img {
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 8px 4px;
    width: 20px;
    padding: 4px;

    &:hover {
      background-color: rgb(250,166,26);
    }
  }
`;

const SocialIcon = styled.img``;

const PaidFor = styled.span`
border: 1px solid black;
padding: 5px;
`

const Footer = () => {
  const [t] = useTranslation('translation');

  const { paidFor, emailPreface } = t('footer', { returnObjects: true });
  const { fileUrls, siteMetadata } = useContext(AssetsContext);

  return (
    <FooterContainer>
      <FooterContent>
        <PaidFor>{paidFor}</PaidFor>
        <p>
          <a href={`mailto:${siteMetadata.footerEmail}`}>
            {emailPreface + ' ' + siteMetadata.footerEmail}
          </a>
        </p>
        <SocialIconsContainer>
          {siteMetadata.socialAccounts.map((account) => (
            <a href={account.url} key={account.iconName} >
              <SocialIcon src={fileUrls[account.iconName]} />
            </a>
          ))}
        </SocialIconsContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
