import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function switchLanguage(language, i18n) {
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
}

const LanguageSwitcherContainer = styled.div`
  margin-left: 10px;
  padding-left: 0px;
  width: 126px;
`;

const LanguageButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.topNavBackgroundColor};
  > button {
    font-size: ${(props) => props.sideNavView && '12px'};
  }
`;

const LanguageButton = styled.button`
  color: ${(props) =>
    props.current
      ? props.theme.topNavLinks.active
      : props.theme.topNavLinks.default};
  font-family: ${(props) => props.theme.fontFamilies.header};
  background: transparent;
  border: 0;
  margin: 2px -1px 2px 0px;
  text-align: left;
  width: ${(p) => p.sideNavView ? p.theme.typeSettings.languageSwitcher.mobileView.buttonWidth : p.theme.typeSettings.languageSwitcher.desktop.buttonWidth};
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: ${(p) => p.sideNavView ? '1.8em' : '1.5em'};
  padding-right: 0.25em;
  font-weight: normal;
  /* font-weight: ${(p) => p.theme.typeSettings.languageSwitcher.fontWeight}; */
  font-size: ${(p) => p.sideNavView ? p.theme.typeSettings.languageSwitcher.mobileView.fontSize : p.theme.typeSettings.languageSwitcher.desktop.fontSize};
  line-height: ${(p) => p.sideNavView ? p.theme.typeSettings.languageSwitcher.mobileView.fontSize : p.theme.typeSettings.languageSwitcher.desktop.fontSize};
  letter-spacing: ${(p) => p.theme.typeSettings.languageSwitcher.letterSpacing};
  &:hover {
    color: ${(props) => props.theme.topNavLinks.hover};
  }
  text-transform: uppercase;
`;

const CurrentLanguageButton = styled(LanguageButton)`
  width: ${(p) => p.sideNavView ? p.theme.typeSettings.languageSwitcher.mobileView.currentButtonWidth : p.theme.typeSettings.languageSwitcher.desktop.currentButtonWidth}!important;
  ${(p) =>
    p.dropDownActive ?
    `
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  outline-bottom: none;
  margin-left: -1px;
  ` : `
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  outline-bottom: none;
  margin-left: -1px;
  `}
  display: flex;
  flex-direction: row;
  align-content: center;
  vertical-align: middle;
  padding: 0.25em 0.25em;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
    line-height: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
  }

`;

const LanguageDropdownContainer = styled.div`
  background-color: white;
  z-index: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.sideNavView ? '25px': '33px'};
  margin-left: -1px;
  padding-right: -1px;
  margin-bottom: -3px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const LanguageIconContainer = styled.span`
  margin-right: 5px;
  width: 20px;
  align-self: center;
  font-size: ${(p) => p.theme.typeSettings.languageSwitcher.desktop.iconFontSize};
  line-height: ${(p) => p.theme.typeSettings.languageSwitcher.desktop.iconFontSize};

  img {
    width: 15px;
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
    line-height: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
  }
  
`;

const LanguageButtonLabel = styled.span`
  /* ${(p) => p.sideNavView && `
    margin-top: 1px;
  `} */
  /* margin-bottom: auto; */
  
`

const LanguageMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LanguageSwitcherIndicator = styled.span`
  margin-left: ${p => p.dropDownActive ? '8px' : '8px'};
  padding-right: ${p => p.dropDownActive ? '1px' : '0px'};
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  transform: ${p => p.dropDownActive ? 'rotate(90deg)' : ''};
  transition: 0.2s;
  font-size: ${(p) => p.theme.typeSettings.languageSwitcher.desktop.fontSize};
  line-height: ${(p) => p.theme.typeSettings.languageSwitcher.desktop.fontSize};

  &::after {
    content: ">";
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
    line-height: ${p => p.theme.typeSettings.languageSwitcher.mobileView.fontSize};
  }

`

const LanguageSwitcher = ({ className, sideNavView }) => {
  const [t, i18n] = useTranslation();
  const [dropDown, setDropDown] = useState(false);
  const buttonContainer = useRef(null);
  const currentLanguageButton = useRef(null);


  function toggleDropDown() {
    if (!dropDown) {
      // console.log('trigger dropdown')
      setDropDown(true);
      document.addEventListener('mousedown', handleClick);

    } else {
      // console.log('retract dropdown')
      setDropDown(false);
      document.removeEventListener('mousedown', handleClick);

    }
  }

  function handleLanguageSwitch(e, language) {
    e.preventDefault();
    document.removeEventListener('mousedown', handleClick);
    // setDropDown(false);
    switchLanguage(language, i18n)
  }

  function handleClick(e) {
    //debugger;
    if (buttonContainer.current && !buttonContainer.current.contains(e.target)) {
      // console.log('outside click detected, handling')
      handleOutsideClick()
    }
    else if (currentLanguageButton && currentLanguageButton.current === e.currentTarget) {
      // console.log('inside click detected, handling')
      toggleDropDown()
    } 
  }

  function handleOutsideClick() {
    setDropDown(false)
    document.removeEventListener('mousedown', handleClick);
  }

  // function retractDropDown(e) {
  //   console.log('retract', e);
  //   if (buttonContainer.current &&
  //       !buttonContainer.current.contains(e.target)
  //   ) {
  //     setDropDown(false);
  //     document.removeEventListener('mousedown', retractDropDown);
  //    } else if (e.target == currentLanguageButton.current) {
  //      console.log('clicked on language button');

  //    } else {

  //   }
  // }

  // function createClickOut {
  //   document.
  // }

  return (
    <LanguageSwitcherContainer className={className}>
      <LanguageButtonsContainer
        sideNavView={sideNavView}
        ref={buttonContainer}
      >
        <LanguageMenuContainer>
          <CurrentLanguageButton sideNavView={sideNavView} onClick={handleClick} dropDownActive={dropDown} ref={currentLanguageButton}>
           {
           // It actually does pass this rule, but eslint can't tell because span 
           // is wrapped by styled-components
           // eslint-disable-next-line jsx-a11y/accessible-emoji
          } <LanguageIconContainer sideNavView={sideNavView} role="img" aria-label="language icon">🌐</LanguageIconContainer>
              <LanguageButtonLabel sideNavView={sideNavView}>{t([i18n.language])}</LanguageButtonLabel>
            <LanguageSwitcherIndicator sideNavView={sideNavView} dropDownActive={dropDown}/>
          </CurrentLanguageButton>
          {dropDown && (
            <LanguageDropdownContainer sideNavView={sideNavView} >
              {i18n.language !== 'en' && (
                <>
                  <LanguageButton
                    sideNavView={sideNavView} 
                    current={i18n.language !== 'en' ? false : true}
                    id="en"
                    onClick={(e) => handleLanguageSwitch(e, 'en')}
                  >
                    <LanguageButtonLabel sideNavView={sideNavView}>{t('en')}</LanguageButtonLabel>
                  </LanguageButton>
                </>
              )}
              {i18n.language !== 'es' && (
                <>
                  <LanguageButton
                    sideNavView={sideNavView} 
                    current={i18n.language !== 'es' ? false : true}
                    id="es"
                    onClick={(e) => handleLanguageSwitch(e, 'es')}
                  >
                    <LanguageButtonLabel sideNavView={sideNavView}>{t('es')}</LanguageButtonLabel>
                  </LanguageButton>
                </>
              )}
              {i18n.language !== 'bn' && (
                <>
                  <LanguageButton
                    sideNavView={sideNavView} 
                    current={i18n.language !== 'bn' ? false : true}
                    id="bn"
                    onClick={(e) => handleLanguageSwitch(e, 'bn')}
                  >
                    <LanguageButtonLabel sideNavView={sideNavView}>{t('bn')}</LanguageButtonLabel>
                  </LanguageButton>
                </>
              )}
            </LanguageDropdownContainer>
          )}
        </LanguageMenuContainer>
      </LanguageButtonsContainer>
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcher;
