import React from 'react';
import styled from 'styled-components';
import { globalHistory as history } from '@reach/router';

import useWindowDimensions from '../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';

import NavItem from './NavComponents/NavItem';

const SideNavItem = styled(NavItem).attrs(p => ({ sideNavStyle: p.$sideNavStyle}))`
  clear: both;
  width: 240px;
  line-height: 1.25em;
  padding: 0.5em 0.75em;
`;

const SideNavContainer = styled.div`
  position: fixed;
  float: right;
  width: 260px;
  display: block;
  word-wrap: normal;
  background-color: ${(props) => props.theme.sideNavBackgroundColor};
  height: ${(props) => props.height + 500}px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0s 0.2s linear, height 0s 0.2s linear,
    visibility 0s 0.2s linear;
  -moz-transition: width 0s 0.2s linear, height 0s 0.2s linear,
    visibility 0s 0.2s linear;
  -ms-transition: width 0s 0.2s linear, height 0s 0.2s linear,
    visibility 0s 0.2s linear;
  -o-transition: width 0s 0.2s linear, height 0s 0.2s linear,
    visibility 0s 0.2s linear;
  transition: width 0s 0.2s linear, height 0s 0.2s linear,
    visibility 0s 0.2s linear;
  z-index: ${(props) => (props.visible ? 1 : -1)};
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;

const SideNavItemsContainer = styled.div`
  padding-left: 25px;
  padding-top: 25px;
`;

const SideNav = ({ active, open, toggleSideNav }) => {

  const {t} = useTranslation(['translation', 'platform']);

  const navItems = t('navItems', { returnObjects: true })

  // let platformSections = t('platform:bodySections:1:params:issues', { returnObjects: true })
  // console.log(platformSections);

  const { height } = useWindowDimensions();
  const { location } = history;

  let splitLocation = location.pathname.split('/');
  let pageName = splitLocation[2];
  // console.log(location);
  // console.log('pageName', pageName)
  return (
    <SideNavContainer height={height} visible={open}>
      <SideNavItemsContainer>
        {navItems ? (
          <>
          {navItems.map(navItem => (
            <SideNavItem
              key={navItem.path+'sidenavbutton'}
              onClick={toggleSideNav}
              $sideNavStyle={true}
              to={navItem.type === "page" ? navItem.path : navItem.type === "anchor" && pageName !== navItem.pageName ? `${navItem.pageName.length > 0 ? '/' + navItem.pageName : ''}/${navItem.path}`: undefined}
              $activePage={pageName === navItem.pageName ? navItem.pageName : undefined}
              preloadNamespaces={navItem.type === "page" && navItem.preloadNamespaces ? navItem.preloadNamespaces : []}
              anchor={navItem.type === "anchor" && pageName === navItem.pageName ? true : undefined}
              external={navItem.type === "external" ? true : undefined }
              href={navItem.type === "external" || "anchor" ? navItem.path : undefined}
              target={navItem.type === "external" ? "_blank" : undefined}
            >
              {navItem.title}
            </SideNavItem>
          ))}
          </>
        ) : (
          <>
          {pageName === '' ? (
            <SideNavItem
              onClick={toggleSideNav}
              $sideNavStyle={true}
              href="#meetSandy"
              anchor={true}
              $activePage={false}
              preloadNamespaces={['about']}
            >
              {t('meetSandy')}
            </SideNavItem>
        ) : (
            <SideNavItem
              onClick={toggleSideNav}
              $sideNavStyle={true}
              to="/#meetSandy"
              $activePage={false}
              preloadNamespaces={['about']}
            >
              {t('meetSandy')}
            </SideNavItem>
        )}
        <SideNavItem
          onClick={toggleSideNav}
          $sideNavStyle={true}
          to="/platform"
          $activePage={pageName === 'platform' ? true : false}
          preloadNamespaces={['platform']}
        >
          {t('platform')}
        </SideNavItem>
        {pageName === '' ? (
            <SideNavItem
              onClick={toggleSideNav}
              $sideNavStyle={true}
              href="#getInvolved"
              anchor={true}
              $activePage={false}
              preloadNamespaces={['about']}
            >
              {t('getInvolved')}
            </SideNavItem>
        ) : (
            <SideNavItem
              onClick={toggleSideNav}
              $sideNavStyle={true}
              to="/#getInvolved"
              $activePage={false}
              preloadNamespaces={['about']}
            >
              {t('getInvolved')}
            </SideNavItem>
        )}
        <SideNavItem
          onClick={toggleSideNav}
          $sideNavStyle={true}
          target="_blank"
          $activePage={false}
          external={true}
          href="https://www.nycvotes.org/campaigns/1331/contributions/new"
        >
          {t('donate')}
        </SideNavItem>
        </>)}
      </SideNavItemsContainer>
    </SideNavContainer>
  );
};

export default SideNav;
