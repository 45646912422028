import { useEffect, useState } from "react";
import vhcheck from 'vh-check';

function useWindowSize() {
  const isClient = typeof window === 'object';


  const getSize = () => {
    let vhcheckInfo = isClient ? vhcheck({
      cssVarName: 'vh-offset',
      force: true,
      // bind: true,
      // updateOnTouch: true
    }) : undefined
  

    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight - ( vhcheckInfo.isNeeded ? vhcheckInfo.offset : 0) : undefined
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize





// import React, { useEffect, useState } from 'react'

// function getWindowDimensions() {
//     const {
//         innerWidth: width,
//         innerHeight: height
//     } = window;

//     return {
//         width,
//         height
//     }
// }

// export default function useWindowDimensions() {
//     const isSSR = typeof window !== "undefined";
//     const [windowDimensions, setWindowDimensions] = useState({
//         width: isSSR ? 1200 : getWindowDimensions().width,
//         height: isSSR ? 800 : getWindowDimensions().height        
//     })



//     useEffect(() => {
//         function handleResize() {
//             setWindowDimensions(getWindowDimensions());
//         }

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return windowDimensions;
// }