import React from 'react';

export const AccordionDownButton = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 980 224"
  >
    <g>
      <path d="M490,145.4L0,0v18.8l490,145.4L980,18.8V0L490,145.4z M0,59.7v18.8L490,224L980,78.5V59.7L490,205.2L0,59.7z" />
    </g>
  </svg>
);

export const LeftButton = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 35.3 58.8"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4,2.7L2.8,29.2c-0.2,0.3-0.2,0.8,0,1.1l26.6,26.5
        c0.2,0.3,0.7,0.3,1,0l3.5-3.4L10.8,30.3c-0.4-0.2-0.4-0.7,0-1.1L33.9,6.1l-3.5-3.5C30.2,2.2,29.7,2.2,29.4,2.7L29.4,2.7z"
    />
  </svg>
);

export const RightButton = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 36.5 60.5"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8,3.8l26.6,26.5c0.3,0.3,0.3,0.8,0,1.2L7.8,58.1
      c-0.3,0.2-0.7,0.2-1.1,0l-3.4-3.5l23.2-23.1c0.2-0.3,0.2-0.8,0-1.2L3.3,7.3l3.4-3.5C7,3.5,7.4,3.5,7.8,3.8L7.8,3.8z"
    />
  </svg>
);

export const ExitButton = ({ className }) => (
  <svg
    className={className}
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="18" x2="6" y1="6" y2="18" />
    <line x1="6" x2="18" y1="6" y2="18" />
  </svg>
);
