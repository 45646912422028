import React, { useContext } from 'react'
import styled from 'styled-components';
import { ModalContext } from './modalContext';
import useWindowDimensions from '../hooks/useWindowDimensions'
import useScrollbarWidth from '../hooks/useScrollbarWidth'
import { RightButton, LeftButton, ExitButton } from './_common';
import { useSwipeable } from 'react-swipeable';

const ModalOverlayContainer = styled.div`
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(10,10,10,0.9);
  z-index: 1001;
  box-sizing: border-box;
`
const ModalOverlayTouchContainer = styled(ModalOverlayContainer)`
  z-index: 1050;
  background-color: transparent;
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: auto 131px;
  grid-template-areas:
    "left exitzone right"
    "thumbstrip thumbstrip thumbstrip";
  max-width: 990px;
  ${p => p.windowW > 990 ? `
      margin-left: ${((p.windowW - 990 - p.scrollbarWidth) / 2)}px;
      margin-right: ${((p.windowW - 990) / 2)}px;
  ` : ``}
`

const ModalContentContainer = styled(ModalOverlayContainer)`
  /* margin-left: 20px;
  margin-right: 20px; */
  max-width: 990px;
  background-color: transparent;
  display: flex;
  padding-top:45px;
  padding-bottom: 15px;
  ${p => p.windowW > 990 ? `
      margin-left: ${((p.windowW - 990 - p.scrollbarWidth) / 2)}px;
      margin-right: ${((p.windowW - 990) / 2)}px;
  ` : ``}
  max-height: calc(100vh - var(--vh-offset, 0px) - 131px); // 131 is div below
  /* max-height: ${p => `calc(${p.windowH}px - 131px)`}; */
`

const ModalOverlayControlColumn = styled.div`
    display: flex;
`

const ModalThumbStripContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  grid-column-start: span 3;
`

const ModalThumbStripInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  transition: 0.25s;
  left: ${p => p.pixelsFromLeft}px;
  bottom: 20px;
  flex-wrap: no-wrap;
`

const ModalThumbSelected = styled.div`
  border: 2px solid ${p => p.theme.colors.jiarGold};
  border-radius: 6px;
  min-height: 104px;
  min-width: 104px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1060;
`

function getPixelsFromLeftForIdx(center, index) {
  return center - index*(104)
}

const ModalThumb = styled.div`

  max-height: 100px;
  min-height: 100px;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  margin: 2px;
  cursor: default;
  &:hover {
      filter: brightness(100%)!important
    }
  ${p => !p.selected && `
    cursor: pointer;
    filter: brightness(50%);
  `}

  > img {

    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

const ModalThumbStrip = ({setCurrentChild, scrollbarWidth, thumbs, width, shiftBy}) => {
  let center = (Math.min(width - scrollbarWidth, 990) /2) - 52
  let pixelsFromLeft =  getPixelsFromLeftForIdx(center, shiftBy)

  return (
    <ModalThumbStripContainer>
      <ModalThumbSelected center={center}/>
      <ModalThumbStripInnerContainer pixelsFromLeft={pixelsFromLeft}>
        { thumbs.map((thumb, i) => <ModalThumb onClick={() => setCurrentChild(i)} selected={i === shiftBy ? true : false}>{thumb}</ModalThumb>) }
      </ModalThumbStripInnerContainer>
    </ModalThumbStripContainer>
  )

}

const ModalButtonContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 25px;
  z-index: 1100;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    > svg {
      fill: ${p => p.theme.colors.jiarGold};
    }
  }
`
const ModalLeftButton = styled(LeftButton)`
  height: 200px;
  width: 15px;
  margin-left: 5px;
  margin-right: auto;
  /* enable-background: new 0 0 980 224; */
  fill: ${p => p.theme.colors.jiarEggshell};
  pointer-events: none;


`;


const ModalRightButton = styled(RightButton)`
  height: 200px;
  width: 15px;
  margin-left: auto;
  margin-right: 5px;
  /* enable-background: new 0 0 980 224; */
  fill: ${p => p.theme.colors.jiarEggshell};
  cursor: pointer;
  pointer-events: none;
`;


const ModalExitButtonContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1999;
  height: 25px;
  cursor: pointer;
  &:hover {
    > svg {
      stroke: ${p => p.theme.colors.jiarGold};
    }
  }
`

const ModalExitButton = styled(ExitButton)`

  height: 25px;
  width: 25px;
  stroke: white;
  cursor: pointer;
  z-index: 1998;
  pointer-events: none;
  
`


const ModalControlLayer = ({childState, thumbs, windowDimensions, getNumberBefore, getNumberAfter, controls}) => {
  // eslint-disable-next-line no-unused-vars
  let [width, _height] = windowDimensions
  let [childLeft, childRight, setInactive] = controls;
  // eslint-disable-next-line no-unused-vars
  const [_currentChild, setCurrentChild] = childState

  const handlers = useSwipeable({
    onSwipedLeft: childRight,
    onSwipedRight: childLeft,
    preventDefaultTouchMoveEvent: true
  })

  let scrollbarWidth = useScrollbarWidth()


  function handleExitClick(e) {
    if(e.target.className && (e.target.className.indexOf('TouchContainer') !== -1 ||
       e.target.className.indexOf('ExitButton') !== -1 ||
      e.target.className === "exitColumn")) {
      setInactive()
    }
  }

  
  return (
    <ModalOverlayTouchContainer {...handlers} scrollbarWidth={scrollbarWidth} windowW={width} onClick={handleExitClick}>
      <ModalExitButtonContainer onClick={handleExitClick} ><ModalExitButton /></ModalExitButtonContainer>
      <ModalOverlayControlColumn style={{gridArea: 'left'}}>
        <ModalButtonContainer onClick={getNumberBefore() ? childLeft : () => {}} >
          { getNumberBefore() > 0 ? (
            <ModalLeftButton />
          ) : (
            <></>
          )}
        </ModalButtonContainer>
      </ModalOverlayControlColumn>
      <div className={"exitColumn"} style={{gridArea: 'exitzone'}}></div>
      <ModalOverlayControlColumn style={{gridArea: 'right'}}>
        <ModalButtonContainer onClick={getNumberAfter() ? childRight : () => {}}>
          { getNumberAfter() > 0 ? (
            <ModalRightButton />
          ) : (
            <></>
          )}
        </ModalButtonContainer>
      </ModalOverlayControlColumn>
      <ModalThumbStrip setCurrentChild={setCurrentChild} scrollbarWidth={scrollbarWidth} style={{gridArea: 'thumbstrip'}} thumbs={thumbs} width={width} shiftBy={getNumberBefore()}/>
    </ModalOverlayTouchContainer>
  )
}



const Modal = () => {
  const { contentState, childState, childLeft, childRight, setInactive, thumbsState } = useContext(ModalContext);
  const [content] = contentState
  const [thumbs] = thumbsState
  // eslint-disable-next-line no-unused-vars
  const [currentChild, _setCurrentChild] = childState

  let {width, height} = useWindowDimensions()

  let scrollbarWidth = useScrollbarWidth()


  function getNumberAfter() {
    return content.length - 1 - currentChild
  }

  function getNumberBefore() {
    return content.length - 1 - getNumberAfter()
  }

  return (
    <>
      {content ? (<ModalOverlayContainer>
                      <ModalControlLayer thumbs={thumbs} windowDimensions={[width, height]} childState={childState} getNumberAfter={getNumberAfter} getNumberBefore={getNumberBefore} controls={[childLeft, childRight, setInactive]} />
                      <ModalContentContainer scrollbarWidth={scrollbarWidth} windowW={width} windowH={height}>
                          {content[currentChild]}
                      </ModalContentContainer>
                  </ModalOverlayContainer>) : <></>}
    </>
  )
}

export default Modal