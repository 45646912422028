import React, { useState, useCallback } from "react";

const ModalContext = React.createContext(null);

const ModalProvider = (props) => {
    const [loadedContent, setLoadedContent] = useState(null)
    const [thumbs, setThumbs] = useState(null)
    const [currentChild, setCurrentChild] = useState(0)
    const [scrollPosition, setScrollPosition] = useState(0)

    const setInactive = useCallback(() => {
        setLoadedContent(null)
        setThumbs(null)
        setCurrentChild(0)
        document.body.style.overflowY = ''
        document.body.style.marginRight = ''
        document.body.style.width = ''
        document.body.style.position = ''
        document.body.style.top = ''
        // console.log('scrolling to ', parseInt(scrollPosition || '0'))
        window.scrollTo(0, parseInt(scrollPosition || '0'));

        setScrollPosition(null)
    }, [scrollPosition])

    const childLeft = () => setCurrentChild(currentChild > 0 ? currentChild - 1 : currentChild)
    const childRight = () => setCurrentChild(currentChild === loadedContent.length - 1 ? currentChild : currentChild + 1)

    const initContent = (content, thumbs, initialChild) => {
      if (!loadedContent) {
        setLoadedContent(content)
        setThumbs(thumbs)
        setCurrentChild(initialChild)
        let startingScrollPosition = window.scrollY
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'
        document.body.style.position = 'fixed'
        document.body.style.top = `-${startingScrollPosition}px`;
        setScrollPosition(startingScrollPosition)
      }
    }

    return (
      <ModalContext.Provider 
        value={{contentState: [loadedContent, setLoadedContent],
          thumbsState: [thumbs, setThumbs],
          childState: [currentChild, setCurrentChild],
          initContent, childLeft, childRight, setInactive, scrollPosition}}>
        {props.children}
      </ModalContext.Provider>
    )
}

export { ModalContext, ModalProvider }